// MARK: React
import React from "react";

// MARK:Next
import Image from "next/image";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
interface IProps
	extends React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
	> {
	loading?: boolean;
	title: string;
}

export default function Button(props: IProps) {
	const { className, loading, ...rest } = props;

	return (
		<button
			{...rest}
			className={`${classes.container} ${className ? className : ""}`}
			disabled={props.loading || props.disabled}
		>
			{props.loading ? (
				<Image
					layout="fill"
					alt="loading"
					src={"/static/tail-spin.svg"}
				/>
			) : (
					props.title
				)}
		</button>
	);
}
