// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Hooks
import { useContactForm } from "../../hooks/useContactForm";

// MARK Components
import TextInput from "../TextInput";
import Button from "../Button";
import Paragraph from "../Paragraph";

// MARK: Resources
import Strings from "../../resources/Strings";

export const ContactForm: React.FC = () => {
	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });
	const { field, submit, loading, submited } = useContactForm(
		strings,
		{
			requesterOptions: {
				handleError: (err, setError) => {
					alert(err.message);
					setError(null);
				},
			},
		},
	);

	return (!submited ? (
		<form
			className={classes.container}
			onSubmit={submit}
		>
			<TextInput {...field("name")} label={strings.general.name}/>
			<TextInput {...field("email")} label={strings.general.email}/>
			<TextInput {...field("phone")} typeNumber={true} label={strings.general.phone}/>
			<TextInput {...field("message")} label={strings.general.message} multipleLine={true}/>
			<Button
				type="submit"
				loading={loading}
				title={strings.general.send}
				className={classes.button}
			/>
		</form>
	) : (
		<div className={classes.contaier}>
			<div className={classes.sumbmitedCard}>
				<Paragraph size="small">
					{strings.alerts.yourContactRequestHasBeenSent}
				</Paragraph>
			</div>
		</div>
	));
};
