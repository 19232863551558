// MARK: React
import React, { CSSProperties } from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
interface IProps {
	title: string;
	textStyle?: string;
	style?: CSSProperties;
}

export function TagTitle(props: IProps) {
	const { title, textStyle, style } = props;

	return (
		<div className={classes.tagTitle} style={style}>
			<p className={textStyle ? textStyle : ""}>{title}</p>
		</div>
	);
}
