// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";
import ITextStyles from "../../interfaces/ITextStyles";

// MARK: Implementation
interface IProps extends ITextStyles {
	textStyle?: string;
}

function color(text: ITextStyles) {
	switch (text.type) {
		case "primary": return classes.primary;
		case "secondary": return classes.secondary;
		case "light": return classes.light;
		default: break;
	}
}

function size(text: ITextStyles) {
	// tslint:disable-next-line: no-small-switch
	switch (text.size) {
		case "small": return classes.small;
		default: break;
	}
}

const Paragraph: React.FC<IProps> = (props) => {
	return (
		<p className={`${classes.paragraph} ${props.textStyle || ""} ${color(props)} ${size(props)}`}>
			{props.children}
		</p>
	);
};

export default Paragraph;
