// MARK: API
import * as api from "@startapp/startapp-user-ssr-api";

// MARK: Resources
import { useInsaneForm, must } from "./useInsaneForm";
import { useRequest, IOptions as IRequesterOptions } from "./useRequest";
import { StringsType } from "../resources/Strings";
interface IOptions {
	requesterOptions?: IRequesterOptions;
}

export function useContactForm(strings: StringsType, options?: IOptions) {
	const { error } = strings;
	const requester = useRequest(options ? options.requesterOptions : undefined);

	const form = useInsaneForm({
		initialValues: {
			name: "",
			email: "",
			phone: "",
			message: "",
		},
		validations: [
			[ "name", must.haveHigherLengthThan(2, error.needLongerName(2)) ],
			[ "phone", must.beValidPhone(error.invalidPhone) ],
			[ "email", must.beValidEmail(error.invalidEmail) ],
		],
		onSubmit: async (data) => {
			await requester.run(() => api.sendContact(data));

			if (typeof gtag !== "undefined") {
				gtag("event", "Send Contact Form", {
					event_category : "Site Forms",
				});
			}
		},
	});

	return {
		...form,
		...requester,
	};
}
