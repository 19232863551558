// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Components
import Paragraph from "../../../startappComponents/Paragraph";
import { LittleTitle } from "../../../startappComponents/LittleTitle";
import { ContactForm } from "../../../startappComponents/ContactForm";
import Title from "../../Title";
import { TagTitle } from "../../TagTitle";

// MARK: Resources
import Strings from "../../../resources/Strings";

const ContactSection: React.FC = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });

	return (
		<div
			id={"contact"}
			className={classes.container}
		>
			<div className={classes.content}>
				<TagTitle title={strings.pages.home.contactSection.tag}/>
				<div className={classes.text}>
					<Title Type="h2" theme="primary">
						{strings.pages.home.contactSection.title}
					</Title>
					<Paragraph textStyle={classes.paragraph}>
						{strings.pages.home.contactSection.subTitle}
					</Paragraph>
					<LittleTitle containerStyle={classes.questionText}>
						{strings.pages.home.contactSection.question}
					</LittleTitle>
					<LittleTitle>
						{strings.pages.home.contactSection.talktoUs}
					</LittleTitle>
				</div>
				<div className={classes.form}>
					<ContactForm/>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;
