// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
type Heading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type Theme = "primary" | "secondary" | "white";

interface IProps {
	Type: Heading;
	theme: Theme;
	customStyle?: string;
}

const Title: React.FC<IProps> = (props) => {
	const {
		children,
		customStyle,
		theme,
		Type,
	} = props;

	return (
		<Type className={`${classes[Type]} ${classes[theme]} ${customStyle || ""}`}>
			{children}
		</Type>
	);
};

export default Title;
