import { useState } from "react";
import { IError, treatError } from "../resources/ErrorHandler";

export interface IOptions {
	handleError?: (error: IError, setError: (error: IError | null) => void) => void;
}

export function useRequest<ReturnType>(options?: IOptions) {
	const [ loading, setLoading ] = useState(false);
	const [ result, setResult ] = useState<ReturnType | null>(null);
	const [ error, setError ] = useState<IError | null>(null);

	const run = async (request: () => Promise<ReturnType>) => {
		if (loading) {
			return;
		}

		try {
			setLoading(true);
			setResult(await request());
		} catch (error) {
			setError(error);

			if (options && options.handleError) {
				options.handleError(error, setError);
			}

			setResult(null);
		} finally {
			setLoading(false);
		}
	};

	return {
		result,
		loading,
		run,
		error,
	};
}
