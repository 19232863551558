// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
interface IProps  {
	alt?: string;
	value?: string;
	name?: string;
	typeNumber?: boolean;
	label?: string;
	textClassName?: string;
	containerClassName?: string;
	placeholder?: string;
	error?: string | null;
	multipleLine?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

function TextInput(props: IProps) {
	// tslint:disable-next-line:insecure-random
	const [rand] = React.useState(Math.random());

	/**
	 * To make the htmlFor of the label
	 * match with the id of the input
	 */
	function getId() {
		return `text_input_${rand}`;
	}

	return (
		<div
			className={`${classes.container} ${props.containerClassName ? props.containerClassName : "" }`}
		>
			<label
				className={classes.textLabel}
				htmlFor={getId()}
			>
				{props.label}
			</label>
			{props.multipleLine ?
				(
					<textarea
						id={getId()}
						name={props.name}
						value={props.value}
						className={classes.inputContainer}
						onChange={props.onChange}
						placeholder={props.placeholder}
						rows={3}
					/>
				) : (
					// tslint:disable-next-line: react-a11y-role-has-required-aria-props
					<input
						id={getId()}
						alt={props.alt}
						type={props.typeNumber && "number"}
						name={props.name}
						value={props.value}
						className={classes.inputContainer}
						onChange={props.onChange}
						placeholder={props.placeholder}
					/>
				)}
			<p className={classes.errorLabel}>
				{props.error}
			</p>
		</div>
	);
}

export default TextInput;
