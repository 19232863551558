// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

interface IProps {
	containerStyle?: string;
}

// MARK: Implementation
export const LittleTitle: React.FC<IProps> = (props) => {
	return (
		<p className={`${classes.container} ${props.containerStyle ? props.containerStyle : "" }`}>
			{props.children}
		</p>
	);
};
